import * as zod from 'zod';

import { ActionRequiredError, Step } from '@sb/remote-control/types';

import {
  MillerWeldParameters,
  MILLER_WFS_BOUNDS,
} from '../../../MillerWeldMachine/types/MillerWeldMachineCommands';
import { convertMetersPerSecond } from '../../convertMetersPerSecond';
import { DeviceKinds } from '../../types/DeviceKinds';

export namespace WeldStepDatabase {
  export const name = 'Weld';
  export const description = '';
  export const deviceKinds = DeviceKinds;
  export const isDecorator = true;
  export const librarySection = Step.LibrarySection.InterfaceWithMachines;
  export const librarySort = '2';

  export const argumentKind = 'Weld';

  export const Arguments = zod.object({
    argumentKind: zod.literal(argumentKind),
    selectedMachineID: zod.string().optional(), // ID of selected machine. Make this optional for backwards compatibility.
    selectedTorchID: zod.string().optional(), // ID of selected torch. Make this optional for backwards compatibility.
    millerWeldParameters: MillerWeldParameters.default({
      voltage: 17,
      mode: 'constantVoltage',
      arcLength: 0.25,
      arcControl: 25,
      wireFeedSpeed: MILLER_WFS_BOUNDS.min, // Meters per second
    }),
    arcStartTime: zod.number().gte(0).default(0), // seconds
    craterFillTime: zod.number().gte(0).default(0), // seconds
    travelSpeed: zod.number().gte(0).default(MILLER_WFS_BOUNDS.min), // meters per second
  });

  export type Arguments = zod.infer<typeof Arguments>;

  export const validator: Step.Validator = ({ stepConfiguration }) => {
    if (stepConfiguration?.args?.argumentKind !== argumentKind) {
      throw new TypeError(`Expected argument kind ${argumentKind}`);
    }

    const { args } = stepConfiguration;

    if (args.selectedMachineID == null) {
      throw new ActionRequiredError({
        kind: 'invalidConfiguration',
        message: 'Must set a weld machine.',
        fieldId: 'selectedMachineID',
      });
    }

    if (args.selectedTorchID == null) {
      throw new ActionRequiredError({
        kind: 'invalidConfiguration',
        message: 'Must set a torch.',
        fieldId: 'selectedTorchID',
      });
    }

    return null;
  };

  export const toRoutineRunner: Step.ToRoutineRunner = ({
    stepConfiguration: { args },
    stepData,
  }) => {
    if (args?.argumentKind !== argumentKind) {
      throw new TypeError(`Expected argument kind ${argumentKind}`);
    }

    return {
      ...stepData,
      stepKind: 'Weld',
      args,
    };
  };
}

WeldStepDatabase satisfies Step.StepKindInfo;
