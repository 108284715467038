import * as zod from 'zod';

import { MillerWeldParameters } from '../../MillerWeldMachine/types/MillerWeldMachineCommands';

export const WeldArguments = zod.object({
  selectedMachineID: zod.string().optional(),
  selectedTorchID: zod.string().optional(),
  millerWeldParameters: MillerWeldParameters,
  craterFillTime: zod.number(), // how long to wait for crater fill
  arcStartTime: zod.number(), // how long to hold before moving});
  travelSpeed: zod.number(),
});

export type WeldArguments = zod.infer<typeof WeldArguments>;
